import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSessionConnectMutation } from "../services/proctorService";
import Loader from "../assets/loader_green.gif";
import Error from "./Error";
import { useAppDispatch, useAppSelector } from "../store";
import { success, failure } from "./sessionSlice";
import { handleError } from "../../src/helpers/sentry";

import { AlertName } from "../../src/helpers/alert-type";
import { alertHelper } from "../../src/helpers/alert-helper";
import { setStorageInfo, setSystemTimeDiff } from "./appSlice";
import * as Sentry from "@sentry/react";
import { SessionScope } from "../globals/enums";
import useRefreshSASToken from "./hooks/useRefreshSASToken";
import gb from "../utils/growthbook";

interface Token {
  sessionId: string;
  sessionType: SessionScope;
  sessionToken: string;
  sessionTokenId: number;
  organizationId: number;
  proviewToken: string;
  streamToken: string;
  startsAt: string;
  endsAt: string;
  streaming_provider: string;
}

const Splash = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const sed_token = urlParams.get("sed_token");
  localStorage.setItem("secondary-device-token", encodeURIComponent(sed_token).replace(
    /%20/g,
    "%2B"
  ));
  const app = useAppSelector((state) => state.session);
  const dispatch = useAppDispatch();
  const [sessionConnect, { error: errorSessionConnect }] = useSessionConnectMutation();

  useRefreshSASToken();

  useEffect(() => {
    const fetchData = async () => {
      const encodedSessionKey = encodeURIComponent(sed_token).replace(
        /%20/g,
        "%2B"
      );
      try {
        const tokenResponse = await sessionConnect({
          secondary_camera_token: encodedSessionKey,
        }).unwrap();
        const token: Token = {
          sessionId: tokenResponse.session_id,
          sessionType: tokenResponse.session_type,
          sessionToken: tokenResponse.session_token,
          sessionTokenId: tokenResponse.session_token_id,
          organizationId: tokenResponse.org_id,
          proviewToken: tokenResponse.proview_token,
          streamToken: tokenResponse.streaming_token,
          startsAt: tokenResponse.slot_starts_at,
          endsAt: tokenResponse.slot_ends_at,
          streaming_provider: tokenResponse.video_provider,
        };
        dispatch(success(token));
        dispatch(setSystemTimeDiff({
          systemTimeDiff: tokenResponse.systemTimeDiff 
        }));
        dispatch(setStorageInfo(tokenResponse.storage))
        alertHelper().raiseAlert({
          alert: AlertName.SecondaryDeviceJoined,
          dispatch: dispatch,
        });
      } catch (error) {
        handleError(error);
        dispatch(failure(error));
      }
    };

    if (sed_token) {
      fetchData();
    }
  }, [sed_token]);


  useEffect(() => {
    if(app.sessionId) {
      Sentry.setTag("session_id", app.sessionId);
    }
  }, [app.sessionId]);

  useEffect(() => {
    if (app.organizationId) {
      gb.setAttributes({
        ...gb.getAttributes(),
        proview_organization_id: app.organizationId,
        proctor_token: app.proviewToken,
      });
    }
  }, [app.organizationId, app.proviewToken]);

  const { streamToken } = app;

  const render = () => {
    if (!sed_token || errorSessionConnect) {
      return <Error />;
    } else if (streamToken) {
      return <Navigate to="/primary" replace={true} />;
    }
    return (
      <div className="loader">
        <img src={Loader} alt="loader" height="150px" width="150px" />
      </div>
    );
  };

  return <>{render()}</>;
};

export default Splash;
