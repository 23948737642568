import { GrowthBook } from "@growthbook/growthbook-react";

const gb = new GrowthBook({
  apiHost: process.env.GROWTHBOOK_API_HOST,
  clientKey: process.env.GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.NODE_ENV !== "production",
  subscribeToChanges: true
});

export default gb;
