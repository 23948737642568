import { useEffect, useRef } from "react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useLocation } from "react-router-dom";
import { canvasDimensionsLandscape, canvasDimensionsPortrait } from "../../config/constants";
import { getCameraGrabBlobPayload } from "../../helpers/blob-payload";
import { useAppDispatch, useAppSelector } from "../../store";
import { enqueueItem } from "../cameraGrabSlice";
import { GrowthbookFeatureFlags } from "../../globals/enums";

const useCameraGrab = () => {
  const storage = useAppSelector((state) => state.app.storage);
  const session = useAppSelector((state) => state.session);
  const dispatch = useAppDispatch();

  const isCameraGrabEnabled = useFeatureIsOn(GrowthbookFeatureFlags.SecondaryCameraGrabEnabled as string);
  const cameraGrabTimer = useRef<NodeJS.Timeout | null>(undefined);
  const storageRef = useRef(storage);
  const { pathname } = useLocation();

  useEffect(() => {
    storageRef.current = storage;
  }, [storage]);

  const grabCamera = () => {
    const videoElement = document.getElementById("secondary_recorder_video_custom") as HTMLVideoElement;
    if (videoElement) {
      const canvas = document.createElement("canvas");
      const portrait = window.matchMedia("(orientation: portrait)").matches;
      const { canvasWidth, canvasHeight } = portrait ? canvasDimensionsPortrait : canvasDimensionsLandscape;
      canvas.height = canvasHeight;
      canvas.width = canvasWidth;
      const context = canvas.getContext("2d");
      context?.drawImage(videoElement, 0, 0, canvasWidth, canvasHeight);

      canvas.toBlob(async (blob) => {
        dispatch(enqueueItem(getCameraGrabBlobPayload(storageRef.current, session, blob)));
      }, "image/png");
    }
  };

  useEffect(() => {
    if (isCameraGrabEnabled && pathname.toLowerCase().includes("live_stream")) {
      cameraGrabTimer.current = setInterval(() => {
        grabCamera();
      }, 10000);
    }
  }, [pathname, isCameraGrabEnabled]);

  return;
};

export default useCameraGrab;
